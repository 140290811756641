/*
 * @Author: duanxin
 * @Date: 2023-08-09 18:08:11
 * @LastEditors: duanxin
 * @LastEditTime: 2023-08-09 18:35:59
 * @Description: 
 */
// export { default as addConfigDialog } from './addConfigDialog.vue'
// 使用异步组件，避免组件内import组件重复注册问题
// const addConfigDialog = () => import('./addConfigDialog.vue')
// const configDialog = () => import('./configDialog.vue')
const aRadio = () => import('./a-radio')
const aSelect = () => import('./a-select')
const aInput = () => import('./a-input')
const aAutoComplete = () => import('./a-auto-complete')
const aTimePicker = () => import('./a-time-picker')
export {
  // addConfigDialog,
  // configDialog,
  aRadio,
  aSelect,
  aInput,
  aAutoComplete,
  aTimePicker
}
