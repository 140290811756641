<template>
  <div class="hotBusiness">
    <div class="searchWrapper" @keydown.enter.prevent="onSubmit">
      <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
      <el-form :inline="true" label-position="right" :model="form" class="demo-form-inline">
        <div class="col_box">
          <div class="col_left">
            <el-form-item :label="$t('searchModule.Rule_Name')">
              <el-input v-model="form.name" placeholder="请输入规则名称"></el-input>
            </el-form-item>
          </div>
          <div class="col_right">
            <el-button type="primary" @click="search" v-if="$route.meta.authority.button.query">{{ $t('button.search') }}</el-button>
          </div>
        </div>
        <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
        <div class="col_box h44">
          <div class="col_left">
            <el-button type="primary" icon="el-icon-plus" @click="add" v-if="$route.meta.authority.button.add"
              >{{ $t('button.addto') }}</el-button
            >
          </div>
        </div>
      </el-form>
    </div>
    <!--列表区域-->
    <div class="tableWrapper bgFFF paddingB10">
      <el-table header-cell-class-name="header-call-style" v-loading="loading" :data="tableData"
        style="width: 100% !important;">
        <el-table-column show-overflow-tooltip :prop="item.prop" :label="item.label" :width="item.width"
          v-for="item in tableCols" align="center" :key="item.prop" :formatter="item.formatter"></el-table-column>
        <el-table-column label="操作" align="center" width="90">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEditClick(scope.row)">{{ $t('button.edit') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页器-->
      <div class="pagerWrapper">
        <div class="block">
          <el-pagination v-if="total > 0" @current-change="handleCurrentChange" :current-page="page" :page-size="pageSize"
            layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <addRuleDialog v-model="dialogVisible" :mode="mode" :ruleId="ruleId" @modifySuccess="modifySuccess"></addRuleDialog>
  </div>
</template>

<script>
import addRuleDialog from './components/addRuleDialog.vue'
export default {
  components: {
    addRuleDialog
  },
  data() {
    return {
      ruleId: '',
      mode: '1',
      dialogVisible: false,
      form: {
        name: "",
      },
      loading: false,
      tableCols: [
        {
          prop: "name",
          label: this.$t("list.Rule_Name"),
          width: "",
        },
        {
          prop: "typeDesc",
          label: "规则类型",
          width: "",
        },
        {
          prop: "plateNumDesc",
          label: "车牌号码",
          width: "",
          formatter: (row) => {
            return row.plateNumDesc || '-'
          },
        },
        {
          prop: "plateColorDesc",
          label: this.$t("list.License_plate_color"),
          width: "",
          formatter: (row) => {
            return row.plateColorDesc || '-'
          },
        },
        {
          prop: "action",
          label: "应用场景",
          width: "",
          formatter: (row) => {
            const map = {
              1: '直接放行',
              2: '直接免费',
              3: '按计费规则',
            }
            return map[row.action] || ''
          },
        },
        {
          prop: "updatedTime",
          label: this.$t("list.update_time"),
          width: "",
        },
        {
          prop: "operatorName",
          label: this.$t("list.Operator"),
          width: "",
        },
      ],
      tableData: [],
      page: 1,
      pageSize: 15,
      total: 0,
    };
  },
  methods: {
    getRuleList() {
      // const res = {
      //   value: {
      //     list: [
      //       {
      //         id: '1',
      //         name: '北京出租车',
      //         typeDesc: '车牌号码&车牌颜色',
      //         plateNumDesc: '开头是“京B”',
      //         plateColorDesc: '绿或蓝',
      //         action: 3,
      //         updatedTime: '2024-09-18 15:12:34',
      //         operatorName: 'admin',
      //       }
      //     ],
      //     total: 1,
      //   }
      // }
      // if (res.value.list.length == 0 && res.value.total > 0) {
      //   this.page--;
      //   this.getRuleList();
      // }
      // this.tableData = res.value.list;
      // this.total = res.value.total * 1;
      this.loading = true;
      let url = "/acb/2.0/spve/list";
      this.$axios
        .get(url, {
          data: {
            pageNum: this.page,
            pageSize: this.pageSize,
            ...this.form,
          },
        })
        .then((res) => {
          if (res.value.list.length == 0 && res.value.total > 0) {
            this.page--;
            this.getRuleList();
          }
          this.tableData = res.value.list;
          this.total = res.value.total * 1;
          this.loading = false;
        });
    },
    search() {
      this.page = 1;
      this.getRuleList();
    },
    modifySuccess() {
      this.getRuleList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getRuleList();
    },
    add() {
      this.mode = '2'
      this.ruleId = ''
      this.dialogVisible = true;
    },
    handleEditClick(item) {
      this.mode = '1'
      this.ruleId = item.id
      this.dialogVisible = true;
    }
  },
  mounted() {
    this.getRuleList();
  },
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.hotBusiness {
  overflow: hidden;
}
</style>
