var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "config-dialog-box",
      attrs: {
        title: _vm.title,
        width: "800px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          attrs: {
            model: _vm.formData,
            "label-width": "120px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { key: "action", attrs: { label: "应用场景", prop: "action" } },
            [
              _c("a-select", {
                staticStyle: { width: "400px" },
                attrs: {
                  data: _vm.actionList,
                  label: "label",
                  value: "value",
                  modifier: "trim",
                },
                model: {
                  value: _vm.formData.action,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "action", $$v)
                  },
                  expression: "formData.action",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              key: "name",
              attrs: { label: _vm.$t("searchModule.Rule_Name"), prop: "name" },
            },
            [
              _vm.formData.action == 3
                ? _c("a-select", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      data: _vm.nameList,
                      label: "name",
                      value: "code",
                      modifier: "trim",
                    },
                    model: {
                      value: _vm.formData.conditionCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "conditionCode", $$v)
                      },
                      expression: "formData.conditionCode",
                    },
                  })
                : _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      maxlength: 8,
                      value: _vm.formData.name,
                      placeholder: "请输入规则名称",
                    },
                    on: { input: _vm.handleNameInput },
                  }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { key: "details", attrs: { label: "规则类型", prop: "details" } },
            [
              _vm._l(_vm.formData.details, function (ruleItem, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticStyle: { display: "flex", "margin-bottom": "16px" },
                    },
                    [
                      _c("a-select", {
                        staticStyle: { width: "110px", "margin-right": "16px" },
                        attrs: {
                          data: _vm.typeList,
                          label: "name",
                          value: "code",
                          modifier: "trim",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.changeType($event, index)
                          },
                        },
                        model: {
                          value: ruleItem.type,
                          callback: function ($$v) {
                            _vm.$set(ruleItem, "type", $$v)
                          },
                          expression: "ruleItem.type",
                        },
                      }),
                      [
                        ruleItem.type == 1
                          ? _c("a-select", {
                              staticStyle: {
                                width: "150px",
                                "margin-right": "16px",
                              },
                              attrs: {
                                data: _vm.plateConditionList,
                                label: "name",
                                value: "code",
                                modifier: "trim",
                              },
                              model: {
                                value: ruleItem.condition,
                                callback: function ($$v) {
                                  _vm.$set(ruleItem, "condition", $$v)
                                },
                                expression: "ruleItem.condition",
                              },
                            })
                          : _vm._e(),
                        ruleItem.type == 2
                          ? _c("a-select", {
                              staticStyle: {
                                width: "150px",
                                "margin-right": "16px",
                              },
                              attrs: {
                                data: _vm.colorConditionList,
                                label: "name",
                                value: "code",
                                modifier: "trim",
                              },
                              model: {
                                value: ruleItem.condition,
                                callback: function ($$v) {
                                  _vm.$set(ruleItem, "condition", $$v)
                                },
                                expression: "ruleItem.condition",
                              },
                            })
                          : _vm._e(),
                      ],
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            ruleItem.type == 1 &&
                            [1, 2, 3, 4, 5, 6, ""].includes(ruleItem.condition)
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-right": "16px" } },
                                  [
                                    ruleItem.condition !== 7 &&
                                    !(
                                      ruleItem.condition == 2 &&
                                      ruleItem.inputType == "2"
                                    )
                                      ? _c("el-input", {
                                          attrs: {
                                            value: ruleItem.value,
                                            placeholder: "请输入车牌参数",
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.handleRuleItemInput(
                                                $event,
                                                index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    ruleItem.condition == 2 &&
                                    ruleItem.inputType == "2"
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              width: "100px",
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                              border: "1px solid #000",
                                              "border-radius": "8px",
                                              height: "40px",
                                              "box-sizing": "border-box",
                                            },
                                          },
                                          [_vm._v("汉字")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ruleItem.type == 2 &&
                            [8, 9, ""].includes(ruleItem.condition)
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-right": "16px" } },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          multiple: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value: ruleItem.value,
                                          callback: function ($$v) {
                                            _vm.$set(ruleItem, "value", $$v)
                                          },
                                          expression: "ruleItem.value",
                                        },
                                      },
                                      _vm._l(
                                        _vm.plateColorList,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            ruleItem.condition == 2
                              ? _c("i", {
                                  staticClass: "el-icon-refresh",
                                  class: {
                                    "refresh-icon-animation":
                                      _vm.showAnimation[ruleItem.key],
                                  },
                                  staticStyle: {
                                    cursor: "pointer",
                                    "font-size": "24px",
                                    "margin-right": "8px",
                                  },
                                  on: {
                                    click: () => {
                                      _vm.iconClick(ruleItem.key, index)
                                    },
                                    animationend: () => {
                                      _vm.animationend(ruleItem.key)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _c("i", {
                              staticClass: "el-icon-circle-plus-outline",
                              staticStyle: {
                                cursor: "pointer",
                                "font-size": "24px",
                                "margin-right": "8px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addRuleItemAfter(index)
                                },
                              },
                            }),
                            _vm.formData.details.length !== 1
                              ? _c("i", {
                                  staticClass: "el-icon-remove-outline",
                                  staticStyle: {
                                    cursor: "pointer",
                                    "font-size": "24px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeRuleItem(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "el-form-item",
            {
              key: "remark",
              attrs: { label: _vm.$t("searchModule.remarks"), prop: "remark" },
            },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { maxlength: 20, placeholder: "请输入备注" },
                model: {
                  value: _vm.formData.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "remark", $$v)
                  },
                  expression: "formData.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.saveButtonLoading },
              on: { click: _vm.handleSave },
            },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }