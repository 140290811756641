<template>
  <el-dialog class="config-dialog-box" :title="title" width="800px" :visible.sync="dialogVisible"
    :close-on-click-modal="false" @close="handleClose">
    <el-form :model="formData" label-width="120px" ref="addForm" :rules="rules">
      <el-form-item label="应用场景" prop="action" key="action">
        <a-select style="width: 400px;" v-model="formData.action" :data="actionList" label="label" value="value"
          modifier="trim"></a-select>
      </el-form-item>
      <el-form-item :label="$t('searchModule.Rule_Name')" prop="name" key="name">
        <a-select style="width: 400px;" v-if="formData.action == 3" v-model="formData.conditionCode" :data="nameList"
          label="name" value="code" modifier="trim"></a-select>
        <el-input style="width: 400px;" v-else :maxlength="8" :value="formData.name" @input="handleNameInput"
          placeholder="请输入规则名称"></el-input>
      </el-form-item>
      <el-form-item label="规则类型" prop="details" key="details">
        <template v-for="(ruleItem, index) in formData.details">
          <div style="display: flex; margin-bottom: 16px;" :key="index">
            <a-select style="width: 110px; margin-right: 16px;" @change="changeType($event, index)"
              v-model="ruleItem.type" :data="typeList" label="name" value="code" modifier="trim"></a-select>
            <template>
              <a-select style="width: 150px; margin-right: 16px;" v-if="ruleItem.type == 1" v-model="ruleItem.condition"
                :data="plateConditionList" label="name" value="code" modifier="trim"></a-select>
              <a-select style="width: 150px; margin-right: 16px;" v-if="ruleItem.type == 2" v-model="ruleItem.condition"
                :data="colorConditionList" label="name" value="code" modifier="trim"></a-select>
            </template>

            <template>
              <div style="display: flex; align-items: center;">
                <div style="margin-right: 16px;"
                  v-if="ruleItem.type == 1 && [1, 2, 3, 4, 5, 6, ''].includes(ruleItem.condition)">
                  <el-input v-if="ruleItem.condition !== 7 && (!(ruleItem.condition == 2 && ruleItem.inputType == '2'))"
                    :value="ruleItem.value" @input="handleRuleItemInput($event, index)"
                    placeholder="请输入车牌参数"></el-input>
                  <div
                    style="width: 100px; display: flex; align-items: center; justify-content: center; border: 1px solid #000; border-radius: 8px; height: 40px; box-sizing: border-box;"
                    v-if="ruleItem.condition == 2 && ruleItem.inputType == '2'">汉字</div>
                </div>
                <div style="margin-right: 16px;" v-if="ruleItem.type == 2 && [8, 9, ''].includes(ruleItem.condition)">
                  <el-select v-model="ruleItem.value" multiple placeholder="请选择">
                    <el-option v-for="item in plateColorList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <i style="cursor: pointer; font-size: 24px; margin-right: 8px;" v-if="ruleItem.condition == 2" @click="() => { iconClick(ruleItem.key, index) }" @animationend="() => { animationend(ruleItem.key) }" class="el-icon-refresh" :class="{'refresh-icon-animation': showAnimation[ruleItem.key]}"></i>
                <i style="cursor: pointer; font-size: 24px; margin-right: 8px;" @click="addRuleItemAfter(index)"
                  class="el-icon-circle-plus-outline"></i>
                <i style="cursor: pointer; font-size: 24px;" v-if="formData.details.length !== 1"
                  @click="removeRuleItem(index)" class="el-icon-remove-outline"></i>
              </div>
            </template>
          </div>
        </template>
      </el-form-item>
      <el-form-item :label="$t('searchModule.remarks')" prop="remark" key="remark">
        <el-input :maxlength="20" v-model="formData.remark" style="width: 400px;" placeholder="请输入备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="saveButtonLoading" @click="handleSave">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { keys, isArray, isNaN, get, reduce } from 'lodash'
import { aRadio, aSelect, aInput } from '@/components/bussinessArrears'
const specialValue = '【汉字】'
const getKey = () => {
  let index = 1;
  return () => {
    return index++;
  }
}
const getKeyFunc = getKey();
export default {
  name: 'addRuleDialog',
  components: {
    aSelect,
  },
  model: {
    prop: 'visible',
    event: 'changeVisible'
  },
  props: {
    // 1 编辑 2 新增
    mode: String,
    visible: {
      type: Boolean,
      default: false,
    },
    ruleId: String,
  },
  data() {
    const validateName = (rule, value, callback) => {
      if (this.formData.action === 3) {
        if (this.formData.conditionCode) {
          callback();
        } else {
          callback(new Error('请选择规则名称'));
        }
      } else {
        if (this.formData.name) {
          if (/^[0-9a-zA-Z\u4e00-\u9fa5]+$/.test(this.formData.name)) {
            if (this.formData.name.length > 8) {
              callback(new Error('最大长度为8'));
            } else {
              callback();
            }
          } else {
            callback(new Error('规则名称格式错误'));
          }
        } else {
          callback(new Error('请输入规则名称'));
        }
      }
    };
    const validateDetail = (rule, value, callback) => {
      for (let i = 0; i < this.formData.details.length; i++) {
        const current = this.formData.details[i];
        if (current.type === 1) {
          if ([1, 2, 3, 4, 5, 6, 7].includes(current.condition)) {
            if (current.condition === 2) {
              if (current.inputType === '1') {
                if (!current.value) {
                  callback(new Error('请输入车牌参数'));
                  return;
                }
              }
            } else if (current.condition === 7) {
            } else {
              if (current.value) {
                if (/^[0-9a-zA-Z\u4e00-\u9fa5*]+$/.test(current.value)) {
                  // callback();
                } else {
                  callback(new Error('车牌参数格式错误'));
                  return;
                }
              } else {
                callback(new Error('请输入车牌参数'));
                return;
              }
            }
          } else {
            callback(new Error('请选择条件'));
            return;
          }
        } else if (current.type === 2) {
          if ([8, 9].includes(current.condition)) {
            if (Array.isArray(current.value) && current.value.length) {
            } else {
              callback(new Error('请选择颜色参数'));
              return;
            }
          } else {
            callback(new Error('请选择条件'));
            return;
          }
        } else {
          callback(new Error('请选择规则类型'));
          return;
        }
      }
      callback();
    };
    return {
      showAnimation: {},
      saveButtonLoading: false,
      rules: {
        action: [
          { required: true, message: '请选择应用场景', trigger: 'change' },
        ],
        name: [
          { required: true, validator: validateName, trigger: ['blur', 'change'] },
        ],
        details: [
          { required: true, validator: validateDetail, trigger: ['blur', 'change'] },
        ],
        remark: [
          { type: 'string', min: 0, max: 20, message: '限20个字符内（含）', trigger: 'change' },
        ],
      },
      // formData.details[0].inputType  1 输入 2 汉字
      formData: {
        name: '',
        action: '',
        conditionCode: '',
        remark: '',
        details: [
          {
            key: getKeyFunc(),
            type: 1,
            condition: '',
            value: '',
            inputType: '1',
          },
        ],
      },
      actionList: [
        {
          value: 1,
          label: '直接放行',
        },
        {
          value: 2,
          label: '直接免费',
        },
        {
          value: 3,
          label: '按计费规则',
        },
      ],
      nameList: [],
      typeList: [
        {
          code: 1,
          name: '车牌号码',
        },
        {
          code: 2,
          name: '车牌颜色',
        },
      ],
      plateConditionList: [
        {
          code: 1,
          name: '包含',
        },
        {
          code: 2,
          name: '不包含',
        },
        {
          code: 3,
          name: '开头是',
        },
        {
          code: 4,
          name: '开头不是',
        },
        {
          code: 5,
          name: '结尾是',
        },
        {
          code: 6,
          name: '结尾不是',
        },
        {
          code: 7,
          name: '为空',
        },
      ],
      colorConditionList: [
        {
          code: 8,
          name: '为其中任一',
        },
        {
          code: 9,
          name: '不为其中任一',
        },
      ],
      plateColorList: [
        {
          value: '0',
          label: '蓝',
        },
        {
          value: '1',
          label: '黄',
        },
        {
          value: '2',
          label: '绿',
        },
        {
          value: '3',
          label: '黄绿',
        },
        {
          value: '4',
          label: '黑',
        },
        {
          value: '5',
          label: '白',
        },
      ]
    };
  },
  computed: {
    nameListMap() {
      const map = {};
      this.nameList.forEach((item) => {
        map[item.code] = item.name;
      })
      return map;
    },
    title() {
      const { mode } = this
      return {
        1: '编辑规则',
        2: '新增规则',
      }[mode];
    },
    dialogVisible: {
      get() {
        return this.visible
      },
      set(state) {
        this.$emit('changeVisible', state)
      }
    },
  },
  watch: {
    'formData.action': function (newValue) {
      if (newValue === 3) {
        this.formData.name = ''
        this.getNameList()
      } else {
        this.formData.conditionCode = ''
      }
      this.$refs.addForm.validateField('name')
    },
    visible(newValue) {
      if (newValue) {
        this.getNameList();
        if (this.mode === '1') {
          this.getRuleDetail()
        } else if (this.mode === '2') {
          this.$refs.addForm.resetFields();
          this.formData = {
            name: '',
            action: '',
            conditionCode: '',
            remark: '',
            details: [
              {
                key: getKeyFunc(),
                type: 1,
                condition: '',
                value: '',
                inputType: '1',
              },
            ],
          };
        }
      } else {
        this.showAnimation = {}
        this.$refs.addForm.resetFields();
      }
    }
  },
  methods: {
    iconClick(key, index) {
      this.$set(this.showAnimation, key, true)
      this.changeInputType(index);
      this.validateFieldDetail();
    },
    animationend(key) {
      this.showAnimation[key] = false;
      this.$set(this.showAnimation, key, false)
    },
    validateFieldDetail() {
      this.$refs.addForm.validateField('details')
    },
    getRuleDetail() {
      let url = `acb/2.0/spve/detail/${this.ruleId}`;
      this.$axios
        .get(url, {})
        .then((res) => {
          const formData = {
            ...res.value,
            details: (res.value.details || []).map(item => {
              if (item.condition === 2 && item.value === specialValue) {
                item.inputType = '2';
                item.value = '';
              } else {
                item.inputType = '1';
              }
              if (item.condition === 7) {
                item.value = ''
              }
              if (Number.parseInt(item.type) === 2 && [8, 9].includes(item.condition)) {
                item.value = item.value.split(',');
              }
              item.key = getKeyFunc();
              return item;
            })
          }
          this.formData = formData
        });
    },
    changeType(value, index) {
      this.formData.details[index].value = ''
      this.formData.details[index].condition = ''
      this.formData.details[index].type = value
    },
    addRuleItemAfter(index) {
      this.formData.details.splice(index + 1, 0, {
        key: getKeyFunc(),
        type: 1,
        condition: '',
        value: '',
        inputType: '1',
      });
    },
    removeRuleItem(index) {
      this.formData.details.splice(index, 1);
    },
    handleNameInput(value) {
      this.formData.name = value.replace(/[^0-9a-zA-Z\u4e00-\u9fa5]/g, '')
    },
    handleRuleItemInput(value, index) {
      this.formData.details[index].value = value.replace(/[^0-9a-zA-Z\u4e00-\u9fa5*]/g, '')
    },
    changeInputType(index) {
      const value = this.formData.details[index].inputType;
      this.formData.details[index].inputType = value === '1' ? '2' : '1';
    },
    // action 3时为选择
    getNameList() {
      // const list = [
      //   {
      //     code: 1,
      //     name: '规则1',
      //   },
      //   {
      //     code: 2,
      //     name: '规则2',
      //   },
      // ];
      // this.nameList = list;
      let url = "/acb/2.0/spve/enum/names";
      this.$axios
        .get(url, {})
        .then((res) => {
          if (res.value.length) {
            this.nameList = res.value;
          } else {
            this.nameList = [];
          }
        });
    },
    getTypeList() {
      const list = [
        {
          code: 1,
          name: '车牌号码',
        },
        {
          code: 2,
          name: '车牌颜色',
        },
      ];
      this.typeList = list;
      // let url = "/acb/2.0/spve/enum/types";
      // this.$axios
      //   .get(url, {})
      //   .then((res) => {
      //     if (res.value.list.length) {
      //       this.typeList = res.value.list;
      //     } else {
      //       this.typeList = [];
      //     }
      //   });
    },
    // 车牌号码规则：1.包含 2.不包含 3.开头是 4.开头不是 5.结尾是 6.结尾不是 7为空
    // 车牌颜色规则：8.为其中任一 9.不为其中任一
    getConditionList() {
      const list = [
        {
          code: 1,
          name: '包含',
        },
        {
          code: 2,
          name: '不包含',
        },
        {
          code: 3,
          name: '开头是',
        },
        {
          code: 4,
          name: '开头不是',
        },
        {
          code: 5,
          name: '结尾是',
        },
        {
          code: 6,
          name: '结尾不是',
        },
        {
          code: 7,
          name: '为空',
        },
        {
          code: 8,
          name: '为其中任一',
        },
        {
          code: 9,
          name: '不为其中任一',
        },
      ];
      this.conditionList = list;
      // let url = "/acb/2.0/spve/enum/conditions";
      // this.$axios
      //   .get(url, {})
      //   .then((res) => {
      //     if (res.value.list.length) {
      //       this.conditionList = res.value.list;
      //     } else {
      //       this.conditionList = [];
      //     }
      //   });
    },
    handleClose() {
      let addForm = this.$refs.addForm
      addForm?.clearValidate();
      this.dialogVisible = false
    },
    handleSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const { formData } = this
          const { id: parentId } = formData;
          const data = {
            ...(this.mode === '1' && parentId ? {
              id: parentId,
            } : {}),
            name: (formData.action === 3 ? (this.nameListMap[formData.conditionCode] || '') : formData.name),
            action: formData.action,
            conditionCode: (formData.action === 3 ? formData.conditionCode : ''),
            remark: formData.remark,
            details: formData.details.map(item => {
              return {
                ...(item.id ? {
                  id: item.id,
                } : {}),
                ...(this.mode === '1' && parentId ? {
                  parentId,
                } : {}),
                type: item.type,
                condition: item.condition,
                ...(item.condition === 7 ? {} : {
                  value: (() => {
                    if (item.type === 1 && item.condition === 2 && item.inputType === '2') {
                      return specialValue;
                    }
                    if (item.type === 1 && item.condition === 7) {
                      return '';
                    }
                    if (Number.parseInt(item.type) === 2 && [8, 9].includes(item.condition)) {
                      if (Array.isArray(item.value) && item.value.length) {
                        return item.value.join(',')
                      }
                    }
                    return item.value;
                  })()
                })
              };
            })
          }
          console.log(1111111, data)
          this.saveButtonLoading = true;
          this.$axios
            .post(
              '/acb/2.0/spve/save', {
                data,
              },
              {
                headers: {
                  "Content-Type": "application/json;",
                },
              }
            ).then((res) => {
              this.$emit('modifySuccess')
              this.handleClose()
            }).finally(() => {
              this.saveButtonLoading = false;
            })
        } else {

        }
      });
    }
  },
};
</script>

<style lang="stylus" scoped>
  .config-dialog-box
    .bussiness-limit
      display flex
      flex-wrap wrap
      align-items center
      .amount-limit
      .out-park-time
        display flex
        align-items center
        .el-input
          max-width 193px
      .out-park-tip-box
        line-height 40px
    .bussiness-box
      .bussiness-box-item
        line-height 24px
        margin 8px 0 16px
        .el-checkbox
          ::v-deep .el-checkbox__label
            color inherit !important
        .tip-item
          margin-left 8px
    .el-form-item
      &.parameter-value-label
        ::v-deep
          .el-form-item__label
            min-width 120px
            width auto !important
      ::v-deep
        .content-tip
          white-space pre-wrap
</style>
<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.refresh-icon-animation {
  animation: rotate 1s linear;
}
</style>
